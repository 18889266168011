import React from 'react';
import './Hero.css';
import Header from '../Header/Header';
import hero_img from '../../assets/hero_image.png';
import hero_img_back from '../../assets/hero_image_back.png';
import Heart from '../../assets/heart.png';
import Calories from '../../assets/calories.png';
import CountUp from 'react-countup';


import { motion } from 'framer-motion'
const Hero = () => {

    const transition = { type: 'spring', duration: 3 }
    const mobile = window.innerWidth <=768 ? true: false;
    return (
        <div className="hero" id='home'> 
            <div className="blur hero-blur"></div>
            <div className="left-h">

                <Header />
                {/* the best ad */}

                <div className="the-best-ad">
                    <motion.div
                        initial={{ left: mobile ? "165px": '238px' }}
                        whileInView={{ left: '8px' }}
                        transition={{ ...transition, type: 'tween' }}




                    ></motion.div>
                    <span>the best fitness club in the town</span>
                </div>

                {/* hero handing */}

                <div className="hero-text">

                    <div>
                        <span className='stroke-text'>Shape </span>
                        <span>Your</span>
                    </div>

                    <div>
                        <span>Ideal body</span>
                    </div>

                    <div >
                        <span>
                            In here we will help you to shape and build
                            your ideal body and live up your life to
                            fullest
                        </span>
                    </div>

                </div>
                {/* figures */}
                <div className="figures">
                    <div>
                        <span><CountUp end={140} start={90} delay='' prefix='+'/></span>
                        <span>expert coachs</span>
                    </div>
                    <div>
                        <span><CountUp end={978} start={928} delay='' prefix='+'/></span>
                        <span>members joined</span>
                    </div>
                    <div>
                        <span><CountUp end={50} start={0} delay='' prefix='+'/></span>
                        <span>fitness programs</span>
                    </div>

                </div>
                {/* Hero btns */}
                <div className="hero-buttons">
                    <buttons className="btn herobtn1">Get Started</buttons>
                    <buttons className="btn herobtn2">Learn More</buttons>
                </div>

            </div>
            <div className="right-h">
                <button className='btn'>Join Now</button>


                <motion.div
                    whileInView={{ right: "4rem" }}
                    transition={transition}
                    initial={{ right: "-1rem" }}

                    className="heart-rate">
                    <img src={Heart} alt="" />
                    <span>Heart Rate</span>
                    <span>116 bmp</span>
                </motion.div>

                {/* hero img */}
                <img src={hero_img} alt="" className='hero-image' />
                <motion.img
                initial={{right: '11rem'}}
                whileInView={{right: '20rem'}}
                transition={transition}
                 src={hero_img_back} alt="" className='hero-image-back' />
                {/* calories */}
                <motion.div
                    initial={{ right: "37rem" }}
                    whileInView={{ right: "28rem" }}
                    transition={transition}
                    className="calories">
                    <img src={Calories} alt="" />
                    <div>
                        <span>Calories Burned</span>
                        <span>220 kcal</span>
                    </div>

                </motion.div>
            </div>

        </div>
    )
}

export default Hero